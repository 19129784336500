<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="login-box" :style="{ height: height }">
        <img src="https://www.achencybe.com/img/pc/login/5-1.jpg" style="width: 600px" />
        <div class="form-box">
          <img src="https://www.achencybe.com/img/pc/logo_1.png" />

          <el-form
            ref="form"
            key="form"
            :rules="rules"
            :model="form"
            style="width: 280px"
          >
            <el-form-item prop="nickName">
              <el-input
                v-model="form.nickName"
                :placeholder="$t('user_info.edit_page_id')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="width: 100%"
                @click="submitForm()"
                >{{$t('user_info.submit')}}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
export default {
  components: { Page, ZuiHeader },
  computed: {
    rules(){
      return{
        nickName: [{ required: true, message: window.vm.$t('user_info.id_check'), trigger: "blur" }],
      }
    }
  },
  data() {
    return {
      height: "",
      form: {
        nickName: "",
        clientName: "",
      },
    };
  },
  watch: {
    "$store.state.userInfo": {
      handler(userInfo) {
        this.form = {
          nickName: userInfo.nickName,
        };
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.height = window.innerHeight + "px";
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api
            .saveClientInfo({
              id: this.$store.state.userInfo.id,
              nickName: this.form.nickName,
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message({
                  type: "success",
                  message: this.$t('user_info.edit_success'),
                  onClose: () => {
                    this.$router.push({ name: "UserInfo" });
                  },
                });
                return this.$api.getClientInfo({ id:  this.$store.state.userInfo.id });
              }
            })
            .then((res) => {
              if (res.code === 0) {
                this.$store.commit("setUserInfo", res.data);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  .form-box {
    width: 500px;
    height: 722px;
    background-color: #fff;
    padding-top: 42px;
    text-align: center;
    img {
      width: 240px;
      margin: 100px 0 100px;
    }
    .text-box {
      width: 280px;
      text-align: center;
      margin: 0 auto;
      .title {
        margin-top: 60px;
        font-size: 30px;
      }
      .sub-title {
        font-size: 12px;
      }
    }
    .tabs-box {
      height: 50px;
      line-height: 50px;
      width: 280px;
      margin: 30px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      position: relative;
      span {
        width: 50%;
        cursor: pointer;
        &.active {
          color: #4aabf2;
        }
      }
      .border-line {
        position: absolute;
        width: 50%;
        bottom: 0;
        height: 2px;
        z-index: 2;
        background-color: #4aabf2;
        transition: left 0.3s;
      }
    }
    .el-form {
      margin: 40px auto 0;
    }
  }
}
</style>